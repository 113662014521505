import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Imagenes = () => {
    const classNames = {
        container: {
            mobile: 'relative w-full h-[120vh]', // Increased height for mobile
            tablet: 'relative w-full h-[90vh]',  // Increased height for tablet
            desktop: 'relative w-full h-[80vh]'  // Increased height for desktop
        },
        image: {
            mobile: 'w-full h-full object-cover',
            tablet: 'w-full h-full object-cover',
            desktop: 'w-full h-full object-cover'
        },
    };

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1024px)' });
    const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

    const getClassName = (element) => {
        if (isMobile) return classNames[element].mobile;
        if (isTablet) return classNames[element].tablet;
        if (isDesktop) return classNames[element].desktop;
        return '';
    };

    return (
        <div className={`${getClassName('container')} bg-gray-100 flex flex-col items-center justify-center p-20`}>
            <div className="relative w-full max-w-6xl"> {/* Increased max width */}
                {/* Horizontal Flex Container for 42% and Heading */}
                <div className="flex items-center justify-center space-x-4 mb-8">
                    {/* 42% container with a border and animation */}
                    <div className="relative p-6 border-4 border-gray-400 rounded-lg animate-pulse bg-white"> {/* Increased padding */}
                        <div className="text-4xl font-bold text-gray-500">42%</div>
                    </div>
                    {/* Heading */}
                    <h2 className="text-2xl font-bold text-gray-500 text-center">
                        Mayor amplitud en campos de visión
                    </h2>
                </div>

                {/* Image comparison section */}
                <div className="flex justify-center items-center">
                    <div className="w-1/2 pr-4">
                        <div className="relative">
                            <svg viewBox="0 0 200 100" className="w-full">
                                <defs>
                                    <clipPath id="lensShape">
                                        <ellipse cx="100" cy="50" rx="90" ry="45" />
                                    </clipPath>
                                </defs>
                                <image href="/AilensComponents/Lentes/DerechaLente.webp" width="200" height="100" clipPath="url(#lensShape)" />
                            </svg>
                            <div className="absolute inset-0 flex items-center justify-between px-4">
                                <span className="text-white">&#9664;&#9664;</span>
                                <span className="text-white">&#9654;&#9654;</span>
                            </div>
                        </div>
                        {/* Text below the image */}
                        <p className="text-center mt-4 text-sm text-gray-600">Diseño digital hasta ahora</p>
                    </div>

                    <div className="w-1/2 pl-4">
                        <div className="relative">
                            <svg viewBox="0 0 200 100" className="w-full">
                                <defs>
                                    <clipPath id="lensShape2">
                                        <ellipse cx="100" cy="50" rx="90" ry="45" />
                                    </clipPath>
                                </defs>
                                <image href="/AilensComponents/Lentes/IzquierdaLente.webp" width="200" height="100" clipPath="url(#lensShape2)" />
                            </svg>
                            <div className="absolute inset-0 flex items-center justify-between px-4">
                                <span className="text-white">&#9664;&#9664;</span>
                                <span className="text-white">&#9654;&#9654;</span>
                            </div>
                        </div>
                        {/* Text below the image */}
                        <p className="text-center mt-4 text-sm text-gray-600">Lentes AILENS</p>
                    </div>
                </div>

                {/* Spacer between image texts and the bottom of the container */}
                <div className="mt-12"></div>
            </div>
        </div>
    );
};

export default Imagenes;
