import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const Numeros = () => {
    const [currentIndex, setCurrentIndex] = React.useState(0);

    const data = [
        { percentage: '96%', text: ['Mejoraron su visión', 'respecto a sus', 'anteriores multifocales'] },
        { percentage: '92%', text: ['Informaron un mejor', 'confort visual respecto', 'a sus anteriores lentes'] },   
        { percentage: '82%', text: ['Mejoraron su', 'experiencia de visión', 'en el anochecer'] },
        { percentage: '88%', text: ['Redujeron el tiempo', 'de adaptación con los', 'lentes'] },
    ];

    const movil = useMediaQuery({ query: '(max-width: 767px)' });
    const tablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1024px)' });
    const computadora = useMediaQuery({ query: '(min-width: 1025px)' });

    const classNames = {
        container: {
            movil: 'relative w-full h-screen flex flex-col justify-center items-center',
            tablet: 'relative w-full h-screen flex flex-col justify-center items-center',
            computadora: 'relative w-full h-screen flex flex-col justify-center items-center'
        },
        border: {
            movil: 'absolute w-[85%] h-1/2 border-2 border-gray-900 rounded-lg z-0 top-[240px]',
            tablet: 'absolute w-[85%] h-1/2 border-2 border-gray-900 rounded-lg z-0 top-[255px]',
            computadora: 'absolute w-[85%] h-1/2 border-2 border-gray-900 rounded-lg z-0 top-[250px]'
        },
        content: {
            movil: 'relative z-10 flex flex-col justify-center items-center w-10/12',
            tablet: 'relative z-10 flex justify-between items-start w-10/12',
            computadora: 'relative z-10 flex justify-between items-start w-10/12'
        },
        square: {
            movil: 'bg-cyan-800 text-white text-2xl font-bold w-20 h-20 flex items-center justify-center rounded-lg mb-4',
            tablet: 'bg-cyan-800 text-white text-2xl font-bold w-20 h-20 flex items-center justify-center rounded-lg mb-4',
            computadora: 'bg-cyan-800 text-white text-2xl font-bold w-20 h-20 flex items-center justify-center rounded-lg mb-4'
        },
        text: {
            movil: 'text-center mb-4',
            tablet: 'text-content',
            computadora: 'text-content'
        },
        slider: {
            movil: 'flex justify-between items-center w-full mb-4',
            tablet: 'hidden',
            computadora: 'hidden'
        },
        sliderButton: {
            movil: 'bg-cyan-800 text-white p-2 rounded-full',
            tablet: 'w-1/4 mt-10 z-10',
            computadora: 'w-1/4 mt-10 z-10'
        }
    };

    const getClassName = (element) => {
        if (movil) return classNames[element].movil;
        if (tablet) return classNames[element].tablet;
        if (computadora) return classNames[element].computadora;
        return '';
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? data.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === data.length - 1 ? 0 : prevIndex + 1));
    };

    const renderMobileContent = () => (
        <>
            <div className={getClassName('slider')}>
                <button onClick={handlePrev} className={getClassName('sliderButton')}>
                    <FaChevronLeft size={24} />
                </button>
                <div className={getClassName('square')}>
                    {data[currentIndex].percentage}
                </div>
                <button onClick={handleNext} className={getClassName('sliderButton')}>
                    <FaChevronRight size={24} />
                </button>
            </div>
            <div className={getClassName('text')}>
                {data[currentIndex].text.map((line, index) => (
                    <div key={index}>{line}</div>
                ))}
            </div>
        </>
    );

    const renderDesktopContent = () => (
        <>
            {data.map((item, index) => (
                <div key={index} className="flex flex-col items-center w-1/4">
                    <div className={getClassName('square')}>
                        {item.percentage}
                    </div>
                    <div className={getClassName('text')}>
                        {item.text.map((line, lineIndex) => (
                            <div key={lineIndex}>{line}</div>
                        ))}
                    </div>
                </div>
            ))}
        </>
    );

    return (
        <div className={getClassName('container')}>
            <div className={getClassName('border')}></div>
            <div className={getClassName('content')}>
                {movil ? renderMobileContent() : renderDesktopContent()}
            </div>
        </div>
    );
}

export default Numeros;
