import React from 'react';
import AnimatedSection from '../Global/Dynamic';

const frases = {
  frase1: { 
    text: "La", 
    style: "font-now-thin text-white",
    x: { mobile: 55, tablet: 55, desktop: 55 }, 
    y: { mobile: 38, tablet: 38, desktop: 38}, 
    fontSize: { mobile: 78, tablet: 78, desktop: 78 }
  },
  frase2: { 
    text: "tecnología de última", 
    style: "font-now text-white",
    x: { mobile: 61, tablet: 61, desktop: 61 }, 
    y: { mobile: 38, tablet: 38, desktop: 38 }, 
    fontSize: { mobile: 78, tablet: 78, desktop: 78 }
  },
  frase3: { 
    text: "generación", 
    style: "font-now-thin text-white",
    x: { mobile: 55, tablet: 55, desktop: 55 }, 
    y: { mobile: 47, tablet: 47, desktop: 47 }, 
    fontSize: { mobile: 78, tablet: 78, desktop:78 }
  },
  frase4: { 
    text: "nos permite", 
    style: "font-now-thin text-white",
    x: { mobile: 78, tablet: 78, desktop: 78 }, 
    y: { mobile: 47, tablet: 47, desktop: 47 }, 
    fontSize: { mobile: 78, tablet: 78, desktop: 78 }
  },
  frase5: { 
    text: "controlar la luz infrarroja.", 
    style: "font-now-thin text-white",
    x: { mobile: 55, tablet: 55, desktop: 55 }, 
    y: { mobile: 56, tablet: 56, desktop: 56 }, 
    fontSize: { mobile: 78, tablet: 78, desktop: 78 }
  },
  frase6: { 
    text: "En nuestro laboratorio podemos", 
    style: "font-now-thin text-white",
    x: { mobile: 55, tablet: 55, desktop: 55 }, 
    y: { mobile: 69, tablet: 69, desktop: 69 }, 
    fontSize: { mobile: 78, tablet: 78, desktop: 78 }
  },
  frase7: { 
    text: "desarrollar un", 
    style: "font-now-thin text-white",
    x: { mobile: 55, tablet: 55, desktop: 55 }, 
    y: { mobile: 78, tablet: 78, desktop: 78 }, 
    fontSize: { mobile: 78, tablet: 78, desktop: 78 }
  },
  frase8: { 
    text: <strong>lente con</strong>, 
    style: "font-now-thin text-white",
    x: { mobile: 81, tablet: 81, desktop: 81 }, 
    y: { mobile: 78, tablet: 78, desktop: 78 }, 
    fontSize: { mobile: 78, tablet: 78, desktop: 78}
  },
  frase9: { 
    text: <strong>protección total</strong>, 
    style: "font-now-thin text-white",
    x: { mobile: 55, tablet: 55, desktop: 55 }, 
    y: { mobile: 86, tablet: 86, desktop: 86 }, 
    fontSize: { mobile: 78, tablet: 78, desktop: 78 }
  },
  frase10: { 
    text: "a los efectos", 
    style: "font-now-thin text-white",
    x: { mobile: 85, tablet: 85, desktop: 85 }, 
    y: { mobile: 86, tablet: 86, desktop: 86 }, 
    fontSize: { mobile: 78, tablet: 78, desktop: 78 }
  },
  frase11: { 
    text: "nocivos del sol.", 
    style: "font-now-thin text-white",
    x: { mobile: 55, tablet: 55, desktop: 55 }, 
    y: { mobile: 94, tablet: 94, desktop: 94 }, 
    fontSize: { mobile: 78, tablet: 78, desktop: 78 }
  },
  frase12: { 
    
    imgSrc: "/StopAge/LentesLaser/Lente.webp",
    style: "font-now-thin", 
    x: { mobile: 103, tablet: 103, desktop: 103 }, 
    y: { mobile: 15, tablet: 15, desktop: 15 }, 
    imgWidth: { mobile: 900, tablet: 900, desktop: 900},  
    imgHeight: { mobile: 800, tablet: 800, desktop: 800 },
  }
};

function LentesLaser() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/StopAge/LentesLaser/Laser.webp"
        isBackground={true}
      />
    </div>
  );
}

export default LentesLaser;