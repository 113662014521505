import React, { useState, forwardRef } from 'react';
import { FaWhatsapp, FaHome, FaTools, FaBlog, FaGlasses, FaEnvelope, FaBars, FaTimes } from 'react-icons/fa';
import useScreenSize from '../../../PaginasLentes/StopAge/Componentes/Global/useScreenSize';

const Navbar1 = forwardRef(({ onClickTecnologia, onHoverContacto, onHomeClick, searchComponent }, ref) => {
  const [showWhatsApp, setShowWhatsApp] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { deviceType } = useScreenSize();

  const handleContacto = () => {
    setShowWhatsApp(true);
    onHoverContacto(true);
  };

  const handleWhatsAppClick = () => {
    window.open('https://api.whatsapp.com/send/?phone=59178600065&text=%Hola&type=phone_number&app_absent=0', '_blank');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-white shadow-md py-3 border-b border-gray-300 fixed top-0 left-0 right-0 z-50" ref={ref}>
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between">
          <button className="md:hidden text-2xl" onClick={toggleMenu}>
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
          <ul className={`${isMenuOpen ? 'flex' : 'hidden'} md:flex flex-col md:flex-row flex-grow justify-center items-center space-y-4 md:space-y-0 md:space-x-8 text-base md:text-xl font-bold text-gray-600 mt-4 md:mt-0`}>
            <li>
              <a href="/" className="flex items-center space-x-2 hover:text-green-600" onClick={onHomeClick}>
                <FaHome className="md:hidden" />
                <span>INICIO</span>
              </a>
            </li>
            <li>
              <a
                className="flex items-center space-x-2 hover:text-green-600 cursor-pointer"
                onClick={onClickTecnologia}
              >
                <FaTools className="md:hidden" />
                <span>NUESTRA TECNOLOGÍA</span>
              </a>
            </li>
            <li>
              <a href="#" className="flex items-center space-x-2 hover:text-green-600">
                <FaBlog className="md:hidden" />
                <span>BLOG</span>
              </a>
            </li>
            <li>
              <a href="#" className="flex items-center space-x-2 hover:text-green-600">
                <FaGlasses className="md:hidden" />
                <span>EXPERIENCIA OPTICENTRO</span>
              </a>
            </li>
            <li>
              <a
                href="#"
                className="flex items-center space-x-2 hover:text-green-600"
                onMouseEnter={handleContacto}
                onMouseLeave={() => onHoverContacto(false)}
                onClick={handleContacto}
              >
                <FaEnvelope className="md:hidden" />
                <span>CONTACTO</span>
              </a>
            </li>
          </ul>
          <div className="hidden md:flex items-center ml-auto">
            {searchComponent}
          </div>
        </div>
      </div>
      {showWhatsApp && (
        <div
          className="fixed bottom-16 right-5 bg-green-600 p-3 rounded-full shadow-md flex items-center justify-center cursor-pointer transition-transform duration-300 ease-in-out transform hover:scale-110"
          onClick={handleWhatsAppClick}
        >
          <FaWhatsapp className="text-white text-2xl" />
        </div>
      )}
    </nav>
  );
});

export default Navbar1;