import React from 'react';
import AnimatedSection from './Componentes/AnimatedSection';
import Chica from './Componentes/Chica/Chica';
import Chica2 from './Componentes/Chica2/Chica2';
import Provocando from './Componentes/Provocando/Provocando';
import Sol from './Componentes/Porcentajes/Sol';
import LuzInfrarroja from './Componentes/LuzInfrarroja/LuzInfraroja';
import Gris from './Componentes/Gris/Gris'; 
import Estufa from './Componentes/Estufa/Estufa';
import Piel from './Componentes/Piel/Piel';
import LenteLaser from './Componentes/LentesLaser/LentesLaser';
import YouTubeVideo from './Componentes/VideoYutu/VideoYutu';
import Lentes from './Componentes/Lentes/Lentes'; 
import Sucursales from '../../PaginaBienvenida/Sucursales/Sucursales';

function StopAge() {
    return (
        <div className="flex flex-col" style={{ overflowY: 'hidden', overflowX: 'auto', margin: 0, padding: 0 }}>
            <AnimatedSection>
                <div className="flex justify-center items-center h-screen">
                    <div className="w-1/4 md:w-1/5 lg:w-1/6"> 
                        <img
                            src="/StopAge/StopAgeLogo/StopAgeLogo.webp"
                            alt="Logo Stop Age"
                            className="w-full h-auto object-contain" 
                        />
                    </div>
                </div>
            </AnimatedSection>
            {[Chica, Chica2, Provocando, Sol, LuzInfrarroja, Gris, Estufa, Piel, LenteLaser,Lentes ,YouTubeVideo, Sucursales].map((Component, index) => (
                <AnimatedSection key={index}>
                    <div className="relative flex items-center justify-center " style={{ margin: 0, padding: 0 }}>
                        {index !== 2 && index !== 6 && index !==10 && index !== 11 && <GlobalImage />}
                        <Component />
                    </div>
                </AnimatedSection>
            ))}
        </div>
    );
}

function GlobalImage() {
    return (
        <div className="absolute top-0 left-[5%] z-10 w-1/6 md:w-1/7 lg:w-1/8">
            <img
                src="/StopAge/Global.webp"
                alt="Global Image"
                className="w-full h-auto object-contain"
            />
        </div>
    );
}

export default StopAge;
