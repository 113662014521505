import React from "react";
import AnimatedSection from "../../PaginasLentes/StopAge/Componentes/Global/Dynamic";
import { s } from "framer-motion/client";


//Area para SunRx
const sunRx = {
    frase0: {
        text: "NUESTRA TECNOLOGÍA",
        style: "font-now-bold",
        x: { mobile: 75, tablet: 80, desktop: 85 }, 
        y: { mobile: 10, tablet: 5, desktop: 5 }, 
        fontSize: { mobile: 110, tablet: 90, desktop: 70 }
    },
    frase1: { 
        imgSrc: '/Tegnologia/SunrxImgTegnologia.webp', 
        x: { mobile: 61, tablet: 61, desktop: 58 }, 
        y: { mobile: 18, tablet: 18, desktop: 18 }, 
        imgWidth: { mobile: 720, tablet: 720 , desktop: 720},
        imgHeight: { mobile: 720, tablet: 720, desktop: 720 },
    },
    frase2: { 
        imgSrc: '/Tegnologia/SunrxLogoTegnologia.webp',
        x: { mobile: 112, tablet: 113, desktop: 111 }, 
        y: { mobile: 37, tablet: 38, desktop: 40 }, 
        imgWidth: { mobile: 550, tablet: 490, desktop: 470 },
        imgHeight: { mobile: 240, tablet: 200, desktop: 170 },
    },
    button: {
        textbutton: "DESCUBRE MÁS",
        style: "font-now-bold",
        x: { mobile: 57, tablet: 58, desktop: 58 },
        y: { mobile: 74, tablet: 72, desktop: 70 },
        btnWidth: { mobile: 460, tablet: 370, desktop: 260 },
        btnHeight: { mobile: 120, tablet: 100, desktop: 80 },        
        fontSize: { mobile: 60, tablet: 50, desktop: 30 }
    },
}


//Area para AiLens
const aiLens = {
    frase1: { 
        imgSrc: '/Tegnologia/AilensLogoTegnologia.webp',  
        x: { mobile: 61, tablet: 61, desktop: 61 }, 
        y: { mobile: 25, tablet: 25, desktop: 30 },
        imgWidth: { mobile: 680, tablet: 650, desktop: 570 },
        imgHeight: { mobile: 195, tablet: 185, desktop: 140 },
    },
    frase2: { 
        imgSrc: '/Tegnologia/AilensImgTegnologia.webp',
        x: { mobile: 105, tablet: 105, desktop: 105 }, 
        y: { mobile: 15, tablet: 15, desktop: 15 }, 
        imgWidth: { mobile: 720, tablet: 720 , desktop: 720},
        imgHeight: { mobile: 720, tablet: 720, desktop: 720 },
    },
    button: {
        textbutton: "DESCUBRE MÁS",
        style: "font-now-bold",
        x: { mobile: 34, tablet: 34, desktop: 34 },
        y: { mobile: 58, tablet: 57, desktop: 56 },
        btnWidth: { mobile: 460, tablet: 370, desktop: 260 },
        btnHeight: { mobile: 120, tablet: 100, desktop: 80 },        
        fontSize: { mobile: 60, tablet: 50, desktop: 30 }
    },
}


//Area para Transitions
const transitions = {
    frase1: { 
        imgSrc: '/Tegnologia/TransitionsImgTegnologia.webp',
        x: { mobile: 61, tablet: 61, desktop: 58 }, 
        y: { mobile: 18, tablet: 18, desktop: 18 }, 
        imgWidth: { mobile: 720, tablet: 720 , desktop: 720},
        imgHeight: { mobile: 720, tablet: 720, desktop: 720 },
    },
    frase2: { 
        imgSrc: '/Tegnologia/TransitionsLogoTegnologia.webp',
        x: { mobile: 109, tablet: 113, desktop: 111 }, 
        y: { mobile: 37, tablet: 38, desktop: 40 }, 
        imgWidth: { mobile: 600, tablet: 560, desktop: 520 },
        imgHeight: { mobile: 190,tablet: 180, desktop: 145 },
    },
    button: {
        textbutton: "DESCUBRE MÁS",
        style: "font-now-bold",
        x: { mobile: 57, tablet: 59, desktop: 59 },
        y: { mobile: 68.5, tablet: 68, desktop: 68 },
        btnWidth: { mobile: 460, tablet: 370, desktop: 260 },
        btnHeight: { mobile: 120, tablet: 100, desktop: 80 },        
        fontSize: { mobile: 60, tablet: 50, desktop: 30 }
    },
}


//Area para StopAge
const stopAge = {
    frase1: { 
        imgSrc: '/Tegnologia/StopAgeLogoTegnologia.webp',
        x: { mobile: 68, tablet: 71, desktop: 68 }, 
        y: { mobile: 30, tablet: 28, desktop: 30 }, 
        imgWidth: { mobile: 360, tablet: 340, desktop: 280 },
        imgHeight: { mobile:  320, tablet:   290, desktop:   240 },
    },
    frase2: { 
        imgSrc: '/Tegnologia/StopAgeImgTegnologia.webp',
        x: { mobile: 105, tablet: 105, desktop: 105 }, 
        y: { mobile: 15, tablet: 15, desktop: 15 }, 
        imgWidth: { mobile: 720, tablet: 720 , desktop: 720},
        imgHeight: { mobile: 720, tablet: 720, desktop: 720 },
    },
    button: {
        textbutton: "DESCUBRE MÁS",
        style: "font-now-bold",
        x: { mobile: 33, tablet: 35, desktop: 34 },
        y: { mobile: 74, tablet: 70, desktop: 68 },
        btnWidth: { mobile: 460, tablet: 370, desktop: 260 },
        btnHeight: { mobile: 120, tablet: 100, desktop: 80 },        
        fontSize: { mobile: 60, tablet: 50, desktop: 30 }
    },
    
}



//Area para BlueClarity
const blueClarity = {
    frase1: { 
        imgSrc: '/Tegnologia/BlueClarityImgTegnologia.webp',
        x: { mobile: 61, tablet: 61, desktop: 58 }, 
        y: { mobile: 18, tablet: 18, desktop: 18 }, 
        imgWidth: { mobile: 720, tablet: 720 , desktop: 720},
        imgHeight: { mobile: 720, tablet: 720, desktop: 720 },
    },
    frase2: { 
        imgSrc: '/Tegnologia/BlueClarityLogoTegnologia.webp',
        x: { mobile: 117, tablet: 118, desktop: 116 }, 
        y: { mobile: 32, tablet: 38, desktop: 40 },
        imgWidth: { mobile: 360, tablet: 320, desktop: 280 },
        imgHeight: { mobile: 300, tablet: 240, desktop: 200 },
    },
    button: {
        textbutton: "DESCUBRE MÁS",
        style: "font-now-bold",
        x: { mobile: 57.5, tablet: 58.3, desktop: 58.3 },
        y: { mobile: 74, tablet: 74, desktop: 72 },
        btnWidth: { mobile: 460, tablet: 370, desktop: 260 },
        btnHeight: { mobile: 120, tablet: 100, desktop: 80 },        
        fontSize: { mobile: 60, tablet: 50, desktop: 30 }
    },
}



//Area para Progresivos
const progresivos = {
    frase1: { 
        imgSrc: '/Tegnologia/ProgresivosLogoTegnologia.webp',
        x: { mobile: 63, tablet: 63, desktop: 60 }, 
        y: { mobile: 35, tablet: 35, desktop: 35 }, 
        imgWidth: { mobile: 640, tablet: 620, desktop: 620 },
        imgHeight: { mobile:   150, tablet:   130, desktop:   120 },
    },
    frase2: { 
        imgSrc: '/Tegnologia/ProgresivosImgTegnologia.webp ',
        x: { mobile: 105, tablet: 105, desktop: 105 }, 
        y: { mobile: 15, tablet: 15, desktop: 15 },
        imgWidth: { mobile: 720, tablet: 720 , desktop: 720},
        imgHeight: { mobile: 720, tablet: 720, desktop: 720 },
    },
    button: {
        textbutton: "DESCUBRE MÁS",
        style: "font-now-bold",
        x: { mobile: 34, tablet: 35, desktop: 34 },
        y: { mobile: 63, tablet: 63, desktop: 61 },
        btnWidth: { mobile: 460, tablet: 370, desktop: 260 },
        btnHeight: { mobile: 120, tablet: 100, desktop: 80 },        
        fontSize: { mobile: 60, tablet: 50, desktop: 30 }
    },
}


//Area para Owl HD
const owlHd = {
    frase1: { 
        imgSrc: '/Tegnologia/OwlImgTegnologia.webp',
        
        x: { mobile: 61, tablet: 61, desktop: 58 }, 
        y: { mobile: 18, tablet: 18, desktop: 18 }, 
        imgWidth: { mobile: 720, tablet: 720, desktop: 720 },
        imgHeight: { mobile:  720, tablet:   720, desktop:   720 },
    },
    frase2: { 
        imgSrc: '/Tegnologia/OwlLogoTegnologia.webp',
        x: { mobile: 115, tablet: 115, desktop: 115 }, 
        y: { mobile: 36, tablet: 34, desktop: 38 }, 
        imgWidth: { mobile: 410, tablet: 370, desktop: 330 },
        imgHeight: { mobile:  260, tablet:  235, desktop:  215 },
    },
    button: {
        textbutton: "DESCUBRE MÁS",
        style: "font-now-bold",
        x: { mobile: 57, tablet: 57.5, desktop: 58.5 },
        y: { mobile: 77, tablet: 73, desktop: 73 },
        btnWidth: { mobile: 460, tablet: 370, desktop: 260 },
        btnHeight: { mobile: 110, tablet: 90, desktop: 70 },        
        fontSize: { mobile: 60, tablet: 50, desktop: 30 }
    },
}

function Tegnologia({ onTechnologyClick }) {
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <AnimatedSection 
                frases={sunRx}
                isBackground={true}
                onButtonClick={() => onTechnologyClick("EnConstruccion")}
            />
            <AnimatedSection 
                frases={aiLens}
                isBackground={true}
                onButtonClick={() => onTechnologyClick("EnConstruccion")}
            />
            <AnimatedSection 
                frases={transitions}
                isBackground={true}
                onButtonClick={() => onTechnologyClick("EnConstruccion")}
            />
            <AnimatedSection 
                frases={stopAge}
                isBackground={true}
                onButtonClick={() => onTechnologyClick("StopAge")}
            />
            <AnimatedSection 
                frases={blueClarity}
                isBackground={true}
                onButtonClick={() => onTechnologyClick("EnConstruccion")}
            />
            <AnimatedSection 
                frases={progresivos}
                isBackground={true}
                onButtonClick={() => onTechnologyClick("EnConstruccion")}
            />
            <AnimatedSection 
                frases={owlHd}
                isBackground={true}
                onButtonClick={() => onTechnologyClick("EnConstruccion")}
            />
        </div>
    );
}

export default Tegnologia;