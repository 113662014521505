import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const AiLens = () => {
    const [scrollY, setScrollY] = useState(0);
    const { ref, inView } = useInView({
        threshold: 0.1,
        triggerOnce: true,
    });

    useEffect(() => {
        const handleScroll = () => setScrollY(window.scrollY);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const numberConfig = {
        '30': { size: 'text-[4vw]', opacity: 'opacity-25', bottom: 'bottom-[62%]', right: 'right-[18%]' },
        '45': { size: 'text-[8vw]', opacity: 'opacity-25', bottom: 'bottom-[72%]', right: 'right-[17%]' },
        '50': { size: 'text-[9vw]', opacity: 'opacity-25', bottom: 'bottom-[64%]', right: 'right-[24%]' },
        '60': { size: 'text-[9.5vw]', opacity: 'opacity-25', bottom: 'bottom-[40%]', right: 'right-[13%]' },
    };

    const numberVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <div className="relative w-full h-screen overflow-hidden">
            <img
                src="/AiLensComponents/Senora/Senora.webp"
                alt="Senior woman with glasses"
                className="w-full h-full object-cover"
            />
                <div ref={ref} className="absolute inset-0 flex items-center justify-center font-montserrat">
                    {Object.entries(numberConfig).map(([num, config]) => (
                    <motion.div
                        key={num}
                        className={`absolute text-white font-thin ${config.size} ${config.opacity} ${config.bottom} ${config.right} tracking-wide`}
                        variants={numberVariants}
                        initial="hidden"
                        animate={inView ? 'visible' : 'hidden'}
                        transition={{ duration: 0.5, delay: parseInt(num) / 100 }}
                    >
                        {num}
                    </motion.div>
                    ))}
                </div>
            <div className="absolute bottom-[20%] left-[77%] transform -translate-x-1/2 text-center text-white space-y-2">
                <p className="text-lg md:text-xl lg:text-2xl">La transición más suave</p>
                <p className="text-lg md:text-xl lg:text-2xl">entre la visión de cerca,</p>
                <p className="text-lg md:text-xl lg:text-2xl">intermedia y de lejos</p>
            </div>
        </div>
    );
};

export default AiLens;
