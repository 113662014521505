import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Senor = () => {
    const classNames = {
        container: {
            movil: 'relative w-full h-screen',
            tablet: 'relative w-full h-screen',
            computadora: 'relative w-full h-screen'
        },
        image: {
            movil: 'w-full h-full object-cover',
            tablet: 'w-full h-full object-cover',
            computadora: 'w-full h-full object-cover'
        },
        textContainer: {
            movil: 'absolute inset-0 flex flex-col justify-center items-start p-4 text-gray ',
            tablet: 'absolute inset-0 flex flex-col justify-center items-start p-6 text-gray ',
            computadora: 'absolute inset-0 flex flex-col justify-center items-start p-8 text-gray '
        },
        title: {
            movil: 'text-xl font-light mb-1',
            tablet: 'text-2xl font-light mb-2',
            computadora: 'text-3xl font-light mb-3'
        },
        subtitle: {
            movil: 'text-2xl font-bold mb-4',
            tablet: 'text-3xl font-bold mb-6',
            computadora: 'text-4xl font-bold mb-8'
        },
        stat: {
            movil: 'mb-2',
            tablet: 'mb-3',
            computadora: 'mb-4'
        },
        statNumber: {
            movil: 'text-3xl font-bold',
            tablet: 'text-4xl font-bold',
            computadora: 'text-5xl font-bold'
        },
        statText: {
            movil: 'text-sm',
            tablet: 'text-base',
            computadora: 'text-lg'
        }
    };

    const movil = useMediaQuery({ query: '(max-width: 767px)' });
    const tablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1024px)' });
    const computadora = useMediaQuery({ query: '(min-width: 1025px)' });

    const getClassName = (element) => {
        if (movil) return classNames[element].movil;
        if (tablet) return classNames[element].tablet;
        if (computadora) return classNames[element].computadora;
        return '';
    };

    return (
        <div className={getClassName('container')}>
            <img 
                src='/AiLensComponents/Senor/Senor.webp' 
                alt='Senor' 
                className={getClassName('image')} 
            />
            <div className={getClassName('textContainer')}>
                <h2 className={getClassName('title')}>Test Clínicos</h2>
                <h3 className={getClassName('subtitle')}>PERFORMANCE VISUAL</h3>
                <div className={getClassName('stat')}>
                    <span className={getClassName('statNumber')}>100%</span>
                    <p className={getClassName('statText')}>Adaptaciones en todas las pruebas clínicas</p>
                </div>
                <div className={getClassName('stat')}>
                    <span className={getClassName('statNumber')}>88%</span>
                    <p className={getClassName('statText')}>Redujeron el tiempo de adaptación respecto a otros lentes progresivos</p>
                </div>
            </div>
        </div>
    );
}

export default Senor;