import React from 'react';
import AnimatedSection from '../Global/Dynamic';

const frases = {
    frase1: { 
        text: "PROTECCIÓN DE LUZ INFRARROJA,", 
        style: "font-now-thin text-white",
        x: { mobile: 52, tablet: 52, desktop: 52 }, 
        y: { mobile: 78, tablet: 78, desktop: 78 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase2: { 
        text: "PARA UNA", 
        style: "font-now-thin text-white",
        x: { mobile: 52, tablet: 52, desktop: 52 }, 
        y: { mobile: 90, tablet: 90, desktop: 90 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80}
    },
    frase3: { 
        text: <strong>MIRADA SIEMPRE JOVEN</strong>, 
        style: "font-now-thin text-white",
        x: { mobile: 72, tablet: 72, desktop: 72 }, 
        y: { mobile: 90, tablet: 90, desktop: 90 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    }
};


function Chica() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/StopAge/Chica/Chica.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Chica;
