import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const Visual = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const cuadrados = {
        1: '01',
        2: '02',
        3: '03',
        4: '04',
        5: '05',
    };

    const frasesCuadrados = {  
        1: [<strong key="0">Adaptación inmediata</strong>, 'en un corto periodo de', 'tiempo.'],
        2: [<strong key="0">Sin efecto swimming</strong>, 'cuando se enfoca en', 'la periferia del lente.'],
        3: [<strong key="0">Campos de visión</strong>, 'optimizados y adaptados', 'al estilo de vida del cliente.'],
        4: [<React.Fragment key="0"><strong>Excelente percepción</strong><br /><strong>espacial.</strong> También en</React.Fragment>, 'las zonas periféricas.'],
        5: [<React.Fragment key="0"><strong>Visión natural</strong> en</React.Fragment>, 'todas las situaciones', '(día y noche).'],
    };

    const classNames = {
        container: {
            movil: 'relative w-full h-screen flex flex-col justify-center items-center',
            tablet: 'relative w-full h-screen flex flex-col justify-center items-center',
            computadora: 'relative w-full h-screen flex flex-col justify-center items-center'
        },
        border: {
            movil: 'absolute w-[90%] h-[45%] border-2 border-gray-900 rounded-lg z-0 top-[19%]',
            tablet: 'absolute w-[90%] h-[40%] border-2 border-gray-900 rounded-lg z-0 top-[10%]',
            computadora: 'absolute w-[90%] h-[40%] border-2 border-gray-900 rounded-lg z-0 top-[20%]'
        },
        content: {
            movil: 'relative z-10 flex flex-col justify-center items-center w-[90%]',
            tablet: 'relative z-10 flex justify-between items-start w-[90%]',
            computadora: 'relative z-10 flex justify-between items-start w-[90%]'
        },
        square: {
            movil: 'bg-cyan-800 text-white text-2xl font-bold w-20 h-20 flex items-center justify-center rounded-lg mb-4',
            tablet: 'bg-cyan-800 text-white text-2xl font-bold w-20 h-20 flex items-center justify-center rounded-lg mb-4',
            computadora: 'bg-cyan-800 text-white text-2xl font-bold w-20 h-20 flex items-center justify-center rounded-lg mb-4 '
        },
        text: {
            movil: 'text-center mb-4',
            tablet: 'text-center',
            computadora: 'text-center'
        },
        image: {
            movil: 'w-[50%] mt-10 z-10',
            tablet: 'w-[25%] mt-10 z-10',
            computadora: 'w-[25%] mt-10 z-10'
        },
        slider: {
            movil: 'flex justify-between items-center w-[90%] mb-4',
            tablet: 'hidden',
            computadora: 'hidden'
        },
        sliderButton: {
            movil: 'bg-cyan-800 text-white p-2 rounded-full',
            tablet: 'bg-cyan-800 text-white p-2 rounded-full',
            computadora: 'bg-cyan-800 text-white p-2 rounded-full'
        }
    };

    const movil = useMediaQuery({ query: '(max-width: 767px)' });
    const tablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 956px)' });
    const computadora = useMediaQuery({ query: '(min-width: 957px)' });

    const getClassName = (element) => {
        if (movil) return classNames[element].movil;
        if (tablet) return classNames[element].tablet;
        if (computadora) return classNames[element].computadora;
        return '';
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? Object.keys(cuadrados).length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === Object.keys(cuadrados).length - 1 ? 0 : prevIndex + 1));
    };

    const renderMobileContent = () => (
        <>
            <div className={getClassName('slider')}>
                <button onClick={handlePrev} className={getClassName('sliderButton')}>
                    <FaChevronLeft size={24} />
                </button>
                <div className={getClassName('square')}>
                    {cuadrados[Object.keys(cuadrados)[currentIndex]]}
                </div>
                <button onClick={handleNext} className={getClassName('sliderButton')}>
                    <FaChevronRight size={24} />
                </button>
            </div>
            <div className={getClassName('text')}>
                {frasesCuadrados[Object.keys(frasesCuadrados)[currentIndex]].map((linea, index) => (
                    <div key={index}>{linea}</div>
                ))}
            </div>
        </>
    );

    const renderDesktopContent = () => (
        <>
            {Object.keys(cuadrados).map((cuadrado) => (
                <div key={cuadrado} className="flex flex-col items-center w-[20%]">
                    <div className={getClassName('square')}>
                        {cuadrados[cuadrado]}
                    </div>
                    <div className={getClassName('text')}>
                        {frasesCuadrados[cuadrado].map((linea, index) => (
                            <div key={index}>{linea}</div>
                        ))}
                    </div>
                </div>
            ))}
        </>
    );

    return (
        <div className={getClassName('container')}>
            <div className={getClassName('border')}></div>
            <div className={getClassName('content')}>
                {movil ? renderMobileContent() : renderDesktopContent()}
            </div>
            <img src='/AiLensComponents/Lentes/DerechaLente.webp' alt='Lente' className={getClassName('image')}/>
        </div>
    );
};

export default Visual;
