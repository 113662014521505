import React from 'react';

function BlueClarity() {
  return (
    <div>
      <h1>BLU CLARITY</h1>
    </div>
  );
}

export default BlueClarity;