import React from 'react';
import AnimatedSection from '../Global/Dynamic';

const frases = {
    frase1: { 
        text: "La exposición a los", 
        style: "font-now-thin text-white",
        x: { mobile: 52, tablet: 52, desktop: 52 }, 
        y: { mobile: 40, tablet: 40, desktop: 40 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase2: { 
        text: <a className="text-white"><strong>rayos UV</strong> es causante</a>, 
        style: "font-now-thin text-white",
        x: { mobile: 52.5, tablet: 52.5, desktop: 52.5 }, 
        y: { mobile: 48, tablet: 48, desktop: 48 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase3: { 
        text: "de los daños en la piel", 
        style: "font-now-thin  text-white",
        x: { mobile: 52.5, tablet: 52.5, desktop: 52.5 }, 
        y: { mobile: 57, tablet: 57, desktop: 57 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase4: { 
        text: "y ojos, provocando el", 
        style: "font-now-thin text-white",
        x: { mobile: 52.5, tablet: 52.5, desktop: 52.5 }, 
        y: { mobile: 65, tablet: 65, desktop: 65 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase5: { 
        text: <strong>envejecimiento</strong>, 
        style: "font-now-thin text-white",
        x: { mobile: 52.5, tablet: 52.5, desktop: 52.5 }, 
        y: { mobile: 75, tablet: 75, desktop: 75 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase6: { 
        text: <strong>prematuro de los</strong>, 
        style: "font-now-thin text-white",
        x: { mobile: 52.5, tablet: 52.5, desktop: 52.5 }, 
        y: { mobile: 83, tablet: 83, desktop: 83 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80 }
    },
    frase7: { 
        text: <strong>tejidos.</strong>, 
        style: "font-now-thin text-white",
        x: { mobile: 52.5, tablet: 52.5, desktop: 52.5 }, 
        y: { mobile: 91, tablet: 91, desktop: 91 }, 
        fontSize: { mobile: 80, tablet: 80, desktop: 80}
    }
};


function Chica2() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <AnimatedSection
        frases={frases}
        imageSrc="/StopAge/Chica2/Chica2.webp"
        isBackground={true}
      />
    </div>
  );
}

export default Chica2;
