import React from 'react';
import AilenLogo from './Componentes/AilensLogo/AilenLogo';
import Senora from './Componentes/Senora/Senora';
import Senor from './Componentes/Senor/Senor';
import Visual from './Componentes/Performance/Visual/Visual';
import Numeros from './Componentes/Performance/Numeros/Numeros';
import Lentes from './Componentes/Lentesitos/Lentes';

function AiLens() {
  return (
    <div >
      <div className="mt-24">
        <AilenLogo />
      </div>
      <div >
        <Senora />
      </div>
      <div>
        <Lentes />
      </div>
      <div >
        <Senor />
      </div>
      <div >
        <Visual />
      </div>
      <div >
        <Numeros />
      </div>
    </div>
  );
}

export default AiLens;
