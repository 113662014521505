import React from 'react';
import { useMediaQuery } from 'react-responsive';

const LogoComponent = () => {
    const classNamesImg = [
        {
            movil: 'flex justify-center items-center py-2', // Ajusta el padding para móvil
            tablet: 'flex justify-center items-center py-4', // Ajusta el padding para tablet
            computadora: 'flex justify-center items-center py-6', // Ajusta el padding para PC
        },
        {
            movil: 'w-64 h-auto',
            tablet: 'w-72 h-auto',
            computadora: 'w-96 h-auto',
        },
    ];

    // Usa useMediaQuery para determinar el tamaño de pantalla
    const movil = useMediaQuery({ query: '(max-width: 767px)' });
    const tablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 956px)' });
    const computadora = useMediaQuery({ query: '(min-width: 957px)' });

    // Devuelve la clase apropiada basada en el tamaño de pantalla
    const getClassName = (index) => {
        if (movil) return classNamesImg[index].movil;
        if (tablet) return classNamesImg[index].tablet;
        if (computadora) return classNamesImg[index].computadora;
        return '';
    };

    return (
        <div className={getClassName(0)}>
            <img 
                src='/AiLensComponents/Logo/AiLensLogo.webp' 
                alt='Logo Ailens' 
                className={getClassName(1)}
            />
        </div>
    );
};

export default LogoComponent;
